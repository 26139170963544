<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="项目" name="0"></el-tab-pane>
      <el-tab-pane label="散号" name="1"></el-tab-pane>
    </el-tabs>
    
    <div class="common-padding">
      <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          @change="commonFun"
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>基准值:</span>
        <el-select
        filterable
          class="common-screen-input"
          v-model="params.referenceType"
          @change="commonFun"
          placeholder="请选择"
        >
          <el-option key="other" label="客户" value="other"> </el-option>
          <el-option key="system" label="系统" value="system"> </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.keyword"
        ></el-input>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            projectKPIList();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
      <br />
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
    </div>
    <el-button class="common-screen-btn" @click="() => download()">导出数据表格</el-button>
    <el-button v-if="roles.includes('超级管理员')" class="common-screen-btn" type="primary" :loading="btnLoading" @click="() => { revokeVisible = true }">撤销当日上传</el-button>
    <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" @click="() => { uploadVisible = true }" :loading="btnLoading">{{activeName == '0' ? '上传项目数据' : '上传散客数据'}}</el-button>
      <!-- <input v-if="!btnLoading" class="common-upload-input" @input="(e) => {uploadKPIXslx(e)}" type="file" /> -->
    </div>
    <input style="display: none" ref="refUploadKPI" @input="(e) => {uploadKPIXslx(e)}" type="file" />
    <el-button class="common-screen-btn" type="text" @click="() => downloadTemplate()">模板下载</el-button>
    <div>
      <div id="echartsLineA"></div>
      <div id="echartsLineB"></div>
      <div id="echartsLineC"></div>
      <div id="echartsLineD"></div>
    </div>

    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
        prop="customerName"
        label="客户名称"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationNum"
        label="总咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        sortable="custom"
        prop="consultationAvgNum"
        label="日均咨询量"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column label="首响(s)" sortable="custom" prop="firsrTime" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.firstTimeFlag == '1' && scope.row.firsrTime ? 'warning' : ''">{{ scope.row.firsrTime || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平响(s)" sortable="custom" prop="avgTime" width="120px">
        <template slot-scope="scope">
          <span :class="scope.row.avgTimeFlag == '1' && scope.row.avgTime ? 'warning' : ''">{{ scope.row.avgTime || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="问答比" sortable="custom" prop="qaRate" width="120px">
        <template slot-scope="scope">
          <span
            :class="scope.row.qaRateFlag == '0' && scope.row.qaRate ? 'warning' : ''"
            >{{
              scope.row.qaRate
                ? (scope.row.qaRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.qaRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="邀评率"
        sortable="custom"
        prop="inviteComments"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.inviteCommentsFlag == '1' && scope.row.inviteComments
                ? 'warning'
                : ''
            "
            >{{
              scope.row.inviteComments
                ? (scope.row.inviteComments * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.inviteComments * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="30s应答率" sortable="custom" prop="thirtyResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.thirtyResponseRate
                ? (scope.row.thirtyResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.thirtyResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="三分钟回复率" sortable="custom" prop="threeMinuteResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.threeMinuteResponseRate
                ? (scope.row.threeMinuteResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.threeMinuteResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="五分钟回复率" sortable="custom" prop="fiveMinuteResponseRate" width="140px">
        <template slot-scope="scope">
          <span >
            {{
              scope.row.fiveMinuteResponseRate
                ? (scope.row.fiveMinuteResponseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.fiveMinuteResponseRate * 100).toFixed(2) + "%"
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="responseRate"
        label="回复率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.responseRate
                ? (scope.row.responseRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.responseRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="conversionRate"
        label="转化率"
        width="120px"
      >
        <template slot-scope="scope">
          <span>{{
              scope.row.conversionRate
                ? (scope.row.conversionRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.conversionRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="满意度" sortable="custom" prop="satisfaction" width="120px">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''
            "
            >{{ scope.row.satisfaction || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="satisfactionDifference"
        :formatter="tableColumn"
        label="满意度差值"
        sortable="custom"
        width="120px"
      ></el-table-column>
      <el-table-column
        label="工单错误率"
        sortable="custom"
        prop="orderErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.orderErrorRateFlag == '1' && scope.row.orderErrorRate
                ? 'warning'
                : ''
            "
            >{{
              scope.row.orderErrorRate
                ? (scope.row.orderErrorRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.orderErrorRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="orderErrorRateDifference"
        :formatter="tableColumn"
        sortable="custom"
        label="工单错误率差值"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="质检错误率"
        sortable="custom"
        prop="qualityErrorRate"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.qualityErrorRateFlag == '1' && scope.row.qualityErrorRate
                ? 'warning'
                : ''
            "
            >{{
              scope.row.qualityErrorRate
                ? (scope.row.qualityErrorRate * 100).toFixed(2) == "NaN"
                  ? "--"
                  : (scope.row.qualityErrorRate * 100).toFixed(2) + "%"
                : "--"
            }}</span
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="qualityErrorRateDifference"
        :formatter="tableColumn"
        label="质检错误率差值"
        sortable="custom"
        width="160px"
      ></el-table-column>
      <el-table-column
        label="服务高压线"
        sortable="custom"
        prop="serviceHighVoltage"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.serviceHighVoltageFlag == '1' && scope.row.serviceHighVoltage
                ? 'warning'
                : ''
            "
            >{{ scope.row.serviceHighVoltage || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="遗漏客诉"
        sortable="custom"
        prop="missFeedback"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            :class="
              scope.row.missFeedback == '1' && scope.row.missFeedback ? 'warning' : ''
            "
            >{{ scope.row.missFeedback || "--" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-service-analysis/shop-kpi',
                  query: {
                    customerId: scope.row.customerId,
                    companyId: params.companyId,
                    customerName: scope.row.customerName,
                    startDate: params.startDate,
                    commonRadio: commonRadio,
                    endDate: params.endDate,
                    referenceType: params.referenceType,
                    guestFlag: params.guestFlag
                  },
                });
              }
            "
            >店铺KPI</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>

    <el-dialog v-dialogDrag title="撤销上传" class="revokeDialog" :append-to-body="true" width="400px" :visible.sync="revokeVisible">
      <div>
        确认撤销当日上传的数据吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="revokeVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {revokeUpload()}">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="上传提醒" class="revokeDialog" :append-to-body="true" width="400px" :visible.sync="uploadVisible">
      <div>
        请先下载模板，模板字段<span style="color: #f00">不可修改</span><br/>
        当前选择的是 “{{params.guestFlag == '0' ? '项目' : '散号'}}” 数据，确认上传文件吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="uploadVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => { confirmChoose() }">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { projectKPIList, uploadKPIXslx, projectKPICharts, revokeUpload } from "../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay, Config } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { Breadcrumb, CommonLatelyDate },
  data() {
    return {
      uploadVisible: false,
      revokeVisible: false, // 撤销二次确认
      activeName: '0', // 项目/散号tabs切换
      loading: false,
      btnLoading: false,
      tableColumn,
      DateTransform,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      roles: this.$sto.get(Config.constants.userInfo).roles, // 角色
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺服务分析", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: 3,
      params: {
        // type: 3, // 筛选类型 7天/30天/90天/ 昨天 ...
        // datePicker: getLatelyDay(90), // 时间范围选择-默认7天
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        referenceType: "other",
        page: 1,
        pageSize: 10,
        guestFlag: '0'
      },
      tableData: [],
      pickerOptions: {},
    };
  },
  created() {
    // if (this.rolesCompany && this.rolesCompany.length == 1) {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
    }
  },
  mounted() {
    this.projectKPIList();
  },
  methods: {
    async revokeUpload () { // 撤销当日上传数据
      let res = await revokeUpload()
      if (res.code == 200) {
        this.$message.success("撤销成功")
      }
      this.revokeVisible = false
    },
    handleClick() {
      this.params.guestFlag = this.activeName
      this.projectKPIList()
      
    },
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.projectKPIList()
    },
    download() {
      // 导出项目KPI
      let params = { ...this.params };
      // params.startDate = DateTransform(params.datePicker[0]);
      // params.endDate = DateTransform(params.datePicker[1]);
      // delete params.datePicker;
      delete params.page;
      delete params.pageSize;
      if (!params.type) {
        delete params.type;
      }
      let url = Config.api.url + "/excel/projectKPIExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%89%B9%E9%87%8F%E6%A8%A1%E6%9D%BF/%E5%AE%A2%E6%9C%8D%E6%95%B0%E6%8D%AE%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF2021-04-06tk.xlsx";
    },
    async projectKPICharts() {
      let params = { ...this.params };
      // if (params.datePicker && params.datePicker.length) {
      //   params.startDate = DateTransform(params.datePicker[0]);
      //   params.endDate = DateTransform(params.datePicker[1]);
      // }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      params.guestFlag = this.activeName
      let resData = (await projectKPICharts(params)).data;
      let echartsDate = [];
      let echartsData = [];
      let arr = ["总咨询量", "首响（s）", "平响（s）", "满意度", "问答比", "邀评率", "回复率", "转化率"];
      let arrKey = [
        "consultationNum",
        "firsrTime",
        "avgTime",
        "satisfaction",
        "qaRate",
        "inviteComments",
        "responseRate",
        "conversionRate"
      ];
      // if (resData && resData.length) {
      resData.map((item) => {
        echartsDate.push(item.excelDate);
      });
      arr.map((arrItem, arrIndex) => {
        echartsData.push({
          data: [],
          name: arrItem,
          type: "line",
          markLine: {
            data: [],
          },
          // stack: "总量",
        });
        resData.map((item, index) => {
          let keyStr = "";
          switch (arrKey[arrIndex]) {
            case "inviteComments":
              keyStr = "inviteCommentsReference";
              break;
            case "qaRate":
              keyStr = "qaRateReference";
              break;
            case "firsrTime":
              keyStr = "firstTimeReference";
              break;
            case "avgTime":
              keyStr = "avgTimeReference";
              break;
            case "satisfaction":
              keyStr = "satisfactionReference";
              break;
          }
          if (arrKey[arrIndex] == "inviteComments" || arrKey[arrIndex] == "qaRate" || arrKey[arrIndex] == "responseRate" || arrKey[arrIndex] == "conversionRate" ||  (arrKey[arrIndex] == "satisfaction" && this.params.guestFlag != '0')) {
            echartsData[arrIndex].data.push((item[arrKey[arrIndex]] * 100).toFixed(2));
            if (index + 2 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr] * 100)],
                },
                {
                  symbol: "none",
                  coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                },
              ]);
            } else if (index + 1 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr] * 100)],
                },
                {
                  symbol: "none",
                  label: {
                    position: "end",
                    formatter:
                      arrItem + " " + Number(resData[index + 1][keyStr] * 100) + "%",
                  },
                  coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                },
              ]);
            }
          } else {
            echartsData[arrIndex].data.push(item[arrKey[arrIndex]]);
            if (index + 2 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr])],
                },
                {
                  symbol: "none",
                  coord: [index + 1, Number(resData[index + 1][keyStr])],
                },
              ]);
            } else if (index + 1 < resData.length && keyStr) {
              if (arrKey[arrIndex] == "firsrTime" || arrKey[arrIndex] == "avgTime") {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr])],
                  },
                  {
                    symbol: "none",
                    label: {
                      position: "end",
                      formatter:
                        arrItem.split("（s）")[0] +
                        " " +
                        Number(resData[index + 1][keyStr]) +
                        "s",
                    },
                    coord: [index + 1, Number(resData[index + 1][keyStr])],
                  },
                ]);
              } else {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr])],
                  },
                  {
                    symbol: "none",
                    label: {
                      position: "end",
                      formatter: arrItem + " " + Number(resData[index + 1][keyStr]),
                    },
                    coord: [index + 1, Number(resData[index + 1][keyStr])],
                  },
                ]);
              }
            }
          }
        });
      });
      // }
      let echartsDataA = echartsData.slice(0, 1);
      let echartsDataB = echartsData.slice(4);
      let echartsDataC = echartsData.slice(1, 3);
      let echartsDataD = echartsData.slice(3, 4);
      this.echartsLine("echartsLineA", echartsDataA, echartsDate, ["总咨询量"]);
      this.echartsLine("echartsLineB", echartsDataB, echartsDate, ["问答比", "邀评率", "回复率", "转化率"]);
      this.echartsLine("echartsLineC", echartsDataC, echartsDate, [
        "首响（s）",
        "平响（s）",
      ]);
      this.echartsLine("echartsLineD", echartsDataD, echartsDate, ["满意度"]);
    },
    echartsLine(byId, echartsData, echartsDate, items) {
      // 折线图
      let yAxis;
      if (byId == "echartsLineB") {
        yAxis = {
          type: "value",
          axisLabel: {
            formatter: "{value} %",
          },
        };
      } else if (byId == "echartsLineD") {
        if (this.params.guestFlag == '0') {
          yAxis = { type: "value", max: 5 };
        } else {
          yAxis = {
            type: "value",
            max: 100,
            axisLabel: {
              formatter: "{value} %",
            },
          };
        }
      } else {
        yAxis = { type: "value" };
      }
      let myChart = this.$echarts.init(document.getElementById(byId));
      let option;
      if (echartsDate && echartsDate.length) {
        option = {
          title: {
            text: byId == "echartsLineA" ? "总咨询量" : byId == "echartsLineB" ? '问答比/邀评率': byId == "echartsLineC" ? '首响（s）/平响（s）' : '满意度',
            x: "0",
            y: "0",
          },
          width: "80%",
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: items,
            right: '10%'
          },
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
            // axisLabel: {
            //   // intervar: 0,
            //   rotate: 10,
            //   // right: 40
            // },
          },
          yAxis: yAxis,
          series: echartsData,
        };
      } else {
        option = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "40%",
          },
          tooltip: {
            trigger: "axis",
          },
          // legend: {
          //   data: ["总咨询量", "首响（s）", "平响（s）", "问答比", "邀评率", "满意度"],
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: {
            type: "value",
          },
          series: echartsData,
        };
      }
      myChart.setOption(option);
    },
    confirmChoose () {
      if (this.$refs.refUploadKPI) {
        this.$refs.refUploadKPI.click()
        this.uploadVisible = false
      }
      
    },
    async uploadKPIXslx(e) {
      // this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      this.loading = true
      let res = (await uploadKPIXslx({ file: file, guestFlag: this.activeName }));
      this.loading = false
      if (res.data) {
        this.$alert(res.data, "失败提示", {
          confirmButtonText: "确定",
        });
      }
      e.target.value = "";
      // this.btnLoading = false;
      if(res.code == 200){
        this.$message.success("上传成功");
      }
      
      this.projectKPIList();
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.projectKPIList();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.projectKPIList();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.projectKPIList();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.projectKPIList();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.commonRadio = 3
      this.params = {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        referenceType: "other",
        page: 1,
        pageSize: 10,
        guestFlag: this.activeName
      };
      if (this.rolesCompany && this.rolesCompany.length) {
        this.params.companyId = this.rolesCompany[0].id;
      }
      this.$refs.commonReset.resetFun(3, DateTransform(getLatelyDay(90)[0]), DateTransform(getLatelyDay(90)[1]))
      this.projectKPIList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.projectKPIList();
    },
    async projectKPIList() {
      // 子账号列表
      this.projectKPICharts();
      this.loading = true;
      let params = { ...this.params };
      // if (params.datePicker && params.datePicker.length) {
      //   params.startDate = DateTransform(params.datePicker[0]);
      //   params.endDate = DateTransform(params.datePicker[1]);
      // }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      params.guestFlag = this.activeName
      let resData = (await projectKPIList({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.revokeDialog {
  /deep/.el-dialog__header {
    padding: 10px 20px;
    .el-dialog__title {
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 14px;
    }
  }
  /deep/.el-dialog__body {
    padding: 20px 40px;
  }
}
.serviceAnalysis-container {
  text-align: left;
  #echartsLineA,
  #echartsLineC {
    width: 49%;
    height: 400px;
    display: inline-block;
    margin: 50px 0 20px 0;
  }
  #echartsLineB,
  #echartsLineD {
    width: 49%;
    display: inline-block;
    margin: 50px 0 20px 0;
    height: 400px;
  }
  .warning {
    color: #f00;
  }
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
